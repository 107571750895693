<template>
  <div v-loading.fullscreen.lock="fullscreenLoading">
    <div class="excel-container">
      <div class="w-full">
        <div class="flex flex-wrap justify-end">
          <div class="flex flex-row py-2">
            <span class="label">Search:</span>
            <el-input v-model="params.searchValue" size="mini" clearable placeholder="Search Vessel Name"></el-input>
          </div>
          <div class="flex flex-row py-2">
            <span class="label">Start Date:</span>
            <el-date-picker v-model="params.startDate" size="mini" prefix-icon="el-icon-date" type="datetime" :style="{ width: '100%' }"
              format="dd/MM/yyyy HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" @change="handleDateChange">
            </el-date-picker>
          </div>
          <div class="flex flex-row py-2">
            <span class="label">End Date:</span>
            <el-date-picker v-model="params.endDate" size="mini" prefix-icon="el-icon-date" type="datetime" :style="{ width: '100%' }"
              format="dd/MM/yyyy HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" @change="handleDateChange">
            </el-date-picker>
          </div>
          <div class="flex flex-row pl-2 py-2">
            <el-button size="mini" type="primary" @click="handleSearch">Search</el-button>
            <el-button size="mini" type="primary" @click="handleExportExcel">Export Excel</el-button>
          </div>
        </div>
      </div>
      <div class="w-full">
        <el-table :data="data">
          <el-table-column width="50" :label="$t('table.no')">
            <template slot-scope="scope">{{ scope.$index + 1 }}</template>
          </el-table-column>
          <el-table-column width="200" prop="bargeName" label="Vessel Name"></el-table-column>
          <el-table-column width="150" prop="lengthOverall" label="LOA"></el-table-column>
          <el-table-column width="150" prop="jettyNo" label="Jetty No"></el-table-column>
          <el-table-column width="150" prop="alongsideDTG" label="Alongside DTG"></el-table-column>
          <el-table-column width="150" prop="pilot" label="Pilot"></el-table-column>
          <el-table-column width="150" prop="companyName" label="Cust."></el-table-column>
          <el-table-column width="150" prop="departureDTG" label="Departure DTG"></el-table-column>
          <el-table-column width="200" prop="pilot2" label="Pilot"></el-table-column>
          <el-table-column width="200" prop="remarks" label="Remarks"></el-table-column>
        </el-table>
      </div>
      <div class="flex justify-end">
        <pagination
          v-show="total > 0"
          style="background: #f2f7fa"
          :total="total"
          :page.sync="params.pageNum"
          :limit.sync="params.pageSize"
          @pagination="handlePagechange"
        ></pagination>
      </div>
    </div>
  </div>
</template>
<script>
import {getPilotExcelList, exportPilotExcelList} from "@/services";

export default {
  name: "Excel",
  data() {
    return {
      params: {
        searchValue: "",
        pageNum: 1,
        pageSize: 20,
        startDate: null,
        endDate: null,
      },
      fullscreenLoading: false,
      total: 0,
      data: [],
    }
  },
  mounted() {
    // userType = 1
    // role = 1
    // console.log('userInfo:', this.$store.state.userInfo)
  },
  methods: {
    handleDateChange(val) {

    },
    handlePagechange(object) {
      this.params.pageNum = object.page
      this.params.pageSize = object.limit
      this.handleSearch()
    },
    handleSearch() {
      getPilotExcelList(this.params).then(res => {
        this.data = res.data.table.rows
        this.total = res.data.table.total
      })
    },
    handleExportExcel() {
      let params = {
        searchValue: this.params.searchValue,
        startDate: this.params.startDate,
        endDate: this.params.endDate,
      }
      exportPilotExcelList(params)
    }
  }
}
</script>
<style scoped>
.excel-container {
  width: 100% !important;
  background-color: white;
  border-radius: 10px;
  border: 1px solid #D5DDED;
  font-size: 11px;
  color: #1D1B20;
  font-family: 'Roboto';
  padding: 20px;

  .label {
    padding: 5px;
    color: #1D1B20;
    font-weight: 600;
    text-align: right;
    width: 90px;
  }
}
::v-deep .el-form {
  display: flex;
}
::v-deep .el-form-item {
  width: 300px;
  display: flex;
  padding-right: 10px;
}
::v-deep .el-form-item__label {
  width: 100px;
  text-align: left;
  font-size: 11px;
  color: #1D1B20;
  font-family: 'Roboto';
  font-weight: 600;
}
::v-deep .el-form-item__content {
  width: 190px;
  text-align: left;
}
::v-deep .el-button {
  font-size: 12px;
}
::v-deep.el-table thead.is-group th.el-table__cell {
  background-color: #F4F6FA;
  border: 1px solid #D5DDED;
}
::v-deep.el-table th.el-table__cell {
  padding: 3px 0px;
}
::v-deep.el-table th.el-table__cell > .cell {
  font-size: 12px;
  color: #1D1B20;
  font-family: 'Roboto';
  font-weight: 900;
  text-align: left;
}

::v-deep .el-table__row > .el-table__cell > .cell {
  color: #1D1B20 !important;
}
::v-deep .el-table__row td {
  border-bottom: 1px solid #D5DDED;
}
</style>